import React from 'react';
import './App.css';
import Register from './Components/RegisterPage/Register';

function App() {
   return (
      <div className="App">
         <Register/>
      </div>
   );
}

export default App;
