import React, { useEffect } from 'react'
import './Success.css'
import SuccessFullLogo from "../Assets/logo.png";
// import ImageLoader from 'react-load-image';

function Success(props) {
   const exit = () => {
      // document.body.style.overflow = "auto";
      // props.setIsShown(false)
      // window.location = ''
   }
   useEffect(() => {
      const body = document.querySelector('body')
      const rContainer = document.querySelector('.r-container')
      body.style.height = '100vh'
      body.style.overflow = 'hidden'
      rContainer.style.height = '100vh'
      rContainer.style.overflow = 'hidden'

      return () => {
         body.style.height = 'auto'
         body.style.overflow = 'auto'
         rContainer.style.height = 'auto'
         rContainer.style.overflow = 'auto'
      }
   }, [])

   return (
      <div className='background-blur' onClick={exit}>
         <div className='flexDiv'>
            <div className="popup" id="popup">
               <img src={SuccessFullLogo} alt="" className='img' loading='lazy' />
            </div>
         </div>
      </div>
   )
}

export default Success