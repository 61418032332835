import React, { useEffect, useState } from "react";
import "./Register.css";
import DateIcon from "../Assets/Group-7.png";
import Date from "../Assets/newDate16.svg";
import Madisage from "../Assets/Medisage.png";
import Idea from "../Assets/Group-13.png";
import Sankalp from "../Assets/Group-3.png";
import Select from "react-select";
import axios from "axios";
import Success from "../SuccessfullPopup/Success";
import ClipLoader from "react-spinners/ClipLoader";
import useWindowSize from "../useWindowSize";

//urls
const POST_URL = process.env.REACT_APP_POST_URL;
const GET_CITY_URL = process.env.REACT_APP_GET_CITY_URL;
const GET_STATE_URL = process.env.REACT_APP_GET_STATE_URL;

function Register() {

   const windowSize = useWindowSize();

   //options for designation dropdown
   const designationOptions = [
      { label: "Nurse", value: "Nurse" },
      { label: "Social worker", value: "Social worker" },
      { label: "Psychologist", value: "Psychologist" },
      { label: "Counsellor", value: "Counsellor" },
      { label: "Others", value: "Others" },
   ]

   //store input data
   const [userData, setUserData] = useState({
      fname: "",
      lname: "",
      mobile_number: "",
      email: "",
      hospital: "",
      state: "",
      city: ""
   });
   const [userDesignation, setUserDesignation] = useState("");

   //display error
   const [fnameError, setFnameError] = useState("");
   const [lnameError, setLnameError] = useState("");
   const [mobileError, setMobileError] = useState("");
   const [HospError, setHospError] = useState("");
   const [DesignationError, setDesignationError] = useState("");
   const [emailError, setEmailError] = useState("");
   const [stateError, setStateError] = useState("");
   const [cityError, setCityError] = useState("");

   //state and city inputs
   const [cityData, setCityData] = useState([]);
   const [stateData, setStateData] = useState([]);
   const [suggestions, setSuggestions] = useState([]);
   const [suggestionsState, setSuggestionsState] = useState([]);

   //for pop
   const [isShown, setIsShown] = useState(false);

   //to check inputs are clicked or not
   const [isFnameFocus, setFnameFocus] = useState(false);
   const [isLnameFocus, setLnameFocus] = useState(false);
   const [isHospFocus, setHospFocus] = useState(false);
   const [isEmailFocus, setEmailFocus] = useState(false);
   const [isMobileFocus, setMobileFocus] = useState(false);
   const [isCityFocus, setCityFocus] = useState(false);
   const [isStateFocus, setStateFocus] = useState(false);

   //loader
   const [isLoading, setIsLoading] = useState(false);

   //get data in states
   const handleInput = (e) => {
      let id = e.target.id;
      let value = e.target.value;
      if (id == "mobile_number") {
         value = value.replace(/[^0-9]/g, "");
         setUserData({ ...userData, [id]: value });
      }
      if (id == "fname") {
         value = value.replace(/ /g, "");
         setUserData({ ...userData, [id]: value });
      }
      if (id == "lname") {
         value = value.replace(/ /g, "");
         setUserData({ ...userData, [id]: value });
      }
      if (id == "hospital") {
         value = value.replace(/[0-9]/g, "");
         setUserData({ ...userData, [id]: value });
      }
      setUserData({ ...userData, [id]: value });
   };

   //option click for city
   const itemClick = (e) => {
      let city = e;
      setUserData({ ...userData, city: city });
      setSuggestions([])
   }

   //option click for state
   const itemClickState = (e) => {
      let state = e;
      setUserData({ ...userData, state: state });
      setSuggestionsState([])
   }

   //validate input data and post
   const validateAndSend = (e) => {
      e.preventDefault();
      if (fnameError != "" || lnameError != "" ||
         mobileError != "" ||
         HospError != "" ||
         DesignationError != "" ||
         emailError != "" ||
         stateError != "" ||
         cityError != "") {
         return;
      }
      document.getElementById("register").disabled = true;
      setIsLoading(true);
      axios.post(POST_URL, {
         fname: userData.fname,
         lname: userData.lname,
         mobile_number: userData.mobile_number,
         email: userData.email,
         designation: userDesignation.value,
         hospital: userData.hospital,
         state: userData.state,
         city: userData.city,
      })
         .then((res) => {
            setIsLoading(false);
            document.getElementById("register").disabled = false;
            setFnameError("");
            setLnameError("");
            setMobileError("");
            setHospError("");
            setEmailError("");
            setDesignationError("");
            setStateError("");
            setCityError("");
            setUserData({ ...userData, fname: "", lname: "", email: "", mobile_number: "", hospital: "", city: "", state: "" });
            setUserDesignation("");
            setIsShown(true);
            window.location = '#r-container'
         })
         .catch((e) => {
            setIsLoading(false)
            setIsShown(false)
            document.getElementById("register").disabled = false;
            if (e.response.data?.errors?.fname) {
               setFnameError("*" + e.response.data?.errors.fname);
            } else {
               setFnameError("");
            }
            if (e.response.data?.errors?.lname) {
               setLnameError("*" + e.response.data?.errors.lname);
            } else {
               setLnameError("");
            }
            if (e.response.data?.errors?.email) {
               setEmailError("*" + e.response.data?.errors.email);
            } else {
               setEmailError("");
            }
            if (e.response.data?.errors?.mobile_number) {
               setMobileError("*" + e.response.data?.errors.mobile_number);
            } else {
               setMobileError("");
            }
            if (e.response.data?.errors?.hospital) {
               setHospError("*" + e.response.data?.errors.hospital);
            } else {
               setHospError("");
            }
            if (userData.hospital.match(/[$&+,:;=?@#|'<>.^*()%!-]/)) { setHospError("Hospital field must not contain special character") }
            if (e.response.data?.errors?.designation) {
               setDesignationError("*" + e.response.data?.errors.designation);
            } else {
               setDesignationError("");
            }
            let check1 = stateData.filter((value) => {
               if (value.toLowerCase() === userData.state.toLowerCase()) {
                  return true
               }
            })
            if (check1.length == 0) {
               setStateError("Invalid State")
            }
            else if (check1.length !== 0) {
               setStateError("")
            }
            if (e.response.data?.errors?.state) {
               setStateError("*" + e.response.data?.errors.state);
            } else {
               setStateError("");
            }

            let check = cityData.filter((value) => {
               if (value.toLowerCase() === userData.city.toLowerCase()) {
                  return true
               }
            })
            if (isCityFocus && check.length == 0) {
               setCityError("City not belongs to the selected state")
            }
            else if (isCityFocus && check.length !== 0) {
               setCityError("")
            }
            if (e.response.data?.errors?.city) {
               setCityError("*" + e.response.data?.errors.city);
            } else {
               setCityError("");
            }
         });
   };

   //get request for state
   useEffect(() => {
      axios.get(GET_STATE_URL).then((res) => {
         setStateData(Object.values(res.data));
      })
   }, [])
   useEffect(() => {
      if (!stateData.includes(userData.state)) {
         const newData = stateData.filter((value) => {
            if (value.toLowerCase().includes(userData.state.toLowerCase())) {
               return value;
            }
         })
         if (userData.state === "") {
            setSuggestionsState([])
         } else {
            setSuggestionsState(newData)
         }
         setCityData([])
         setUserData({ ...userData, city: '' })
      }
   }, [userData.state])


   //post request for city
   useEffect(() => {
      if (userData.state) {
         axios.post(GET_CITY_URL + `/${userData.state}`).then((res) => {
            setCityData(Object.values(res.data));
         }).catch((e) => {

         })

         if (!cityData.includes(userData.city)) {
            const newData = cityData.filter((value) => {
               if (value.toLowerCase().includes(userData.city.toLowerCase())) {
                  return value;
               }
            })
            if (userData.city === "") {
               setSuggestions([])
            } else {
               setSuggestions(newData)
            }
            let check = stateData.filter((value) => {
               if (value.toLowerCase() === userData.state.toLowerCase()) {
                  return true
               }
            })
            console.log(check);
            if (check.length != 0) {
               document.getElementById("city").disabled = false;
            }
            if (check.length == 0) {
               document.getElementById("city").disabled = true;
            }
         }
      } else if (!stateData.includes(userData.state)) {
         document.getElementById("city").disabled = true;
      }
   }, [userData.city, userData.state])

   //validation
   useEffect(() => {
      if (isFnameFocus && userData.fname) { setFnameError("") }
      else if (isFnameFocus && userData.fname.length === 0) { setFnameError("First Name is required") }
      if (!userData.fname.match(/^[A-Za-z]*$/)) { setFnameError("First name must not contain special characters") }
      if (isFnameFocus && userData.fname.length >= 25) { setFnameError("The First Name may not be greater than 25 characters.") }
   }, [userData.fname])


   useEffect(() => {
      if (isLnameFocus && userData.lname) { setLnameError("") }
      else if (isLnameFocus && userData.lname.length === 0) { setLnameError("Last Name is required") }
      if (!userData.lname.match(/^[A-Za-z]*$/)) { setLnameError("Last name must not contain special characters") }
      if (isLnameFocus && userData.lname.length >= 25) { setLnameError("The Last Name may not be greater than 25 characters.") }
   }, [userData.lname])

   useEffect(() => {
      if (userData.email.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) { setEmailError("") }
      else if (isEmailFocus && !userData.email.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) {
         setEmailError("Invalid Email Format")
      }
   }, [userData.email])

   useEffect(() => {
      if (userData.mobile_number.length === 10) { setMobileError("") }
      if (isMobileFocus && !/^\d+$/.test(userData.mobile_number)) {
         setMobileError("Only digits are required.")
      }
      else if (isMobileFocus && userData.mobile_number.length != 10) { setMobileError("The mobile number must be of 10 digits long") }
   }, [userData.mobile_number])

   useEffect(() => {
      if (userDesignation) { setDesignationError("") }
   }, [userDesignation])

   useEffect(() => {
      if (userData.hospital) { setHospError("") }
      else if (isHospFocus && userData.hospital.length === 0) { setHospError("Hospital field is required") }
      if (userData.hospital.match(/[$&+,:;=?@#|'<>.^*()%!-]/)) { setHospError("Hospital field must not contain special character") }
   }, [userData.hospital])

   useEffect(() => {
      if (userData.state) { setStateError("") }
      let check = stateData.filter((value) => {
         if (value.toLowerCase() === userData.state.toLowerCase()) {
            userData.state = value
            return true
         }
      })
      if (isStateFocus && check.length != 0) {
         setStateError("")
         setSuggestionsState([])
      }
      else if (isStateFocus && check.length == 0) {
         setStateError("Invalid State")
      }
   }, [userData.state])

   useEffect(() => {
      if (userData.city) { setCityError("") }
      let check = cityData.filter((value) => {
         if (value.toLowerCase() === userData.city.toLowerCase()) {
            userData.city = value
            return true
         }
      })
      if (isCityFocus && check.length != 0) {
         setCityError("")
         setSuggestions([])
      }
      else if (isCityFocus && check.length == 0) {
         if (userData.city == "") {
            setCityError("Select your City")
         } else {
            setCityError("City not belongs to the selected state")
         }
      }
   }, [userData.city])



   return (
      <div className="r-main">
         {
            isShown && (
               <Success setIsShown={setIsShown} />
            )
         }
         <div className="r-container" id="r-container">
            <div className="r-left">

               <div className="r-modules">
                  <div className="r-module1">
                     <div className="module1-1">
                        <p className="r-text">Module</p>
                        <div className="r-circle-outer">
                           <div className="r-circle1">1</div>
                        </div>
                        <div className="r-desc">
                           <p>Understanding schizophrenia</p>
                        </div>
                     </div>
                     <div className="module1-1">
                        <p className="r-text">Module</p>
                        <div className="r-circle-outer">
                           <div className="r-circle1">2</div>
                        </div>
                        <div className="r-desc">
                           <p>Antipsychotic treatment of schizophrenia</p>
                        </div>
                     </div>
                  </div>
                  <div className="r-module1">
                     <div className="module1-1">
                        <p className="r-text">Module</p>
                        <div className="r-circle-outer">
                           <div className="r-circle1">3</div>
                        </div>
                        <div className="r-desc">
                           <p>
                              Importance of adherence and strategies to improve adherence
                           </p>
                        </div>
                     </div>
                     <div className="module1-1">
                        <p className="r-text">Module</p>
                        <div className="r-circle-outer">
                           <div className="r-circle1">4</div>
                        </div>
                        <div className="r-desc">
                           <p>
                              Psychosocial education for caregivers of patients with
                              schizophrenia
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
               {/* <div className="r-date">
                  <img src={DateIcon} alt="" width="30px" height="30px" />{" "}
                  <img src={Date} alt="" height="30px" />
               </div> */}
            </div>
            {
               windowSize > 900 && (
                  <div className="r-middle">
                     <div className="r-logo">
                        <img src={Sankalp} alt="" className="s-logo" />
                     </div>
                     <div className="r-logo">
                        <img src={Idea} alt="" className="s-idea-logo" />
                     </div>
                  </div>
               )
            }
            <div className="r-right">
               {
                  windowSize < 900 ? (
                     <div className="r-logo">
                        <img src={Sankalp} alt="" className="s-logo" />
                     </div>
                  ) : (
                     ""
                  )
               }
               <form onSubmit={validateAndSend} autocomplete="off">
                  <label className="label" htmlFor="fname">
                     First Name
                  </label>
                  <input
                     className="input"
                     type="text"
                     id="fname"
                     onChange={handleInput}
                     value={userData.fname}
                     onFocus={() => setFnameFocus(true)}
                  />
                  <a href className="error">
                     {fnameError}
                  </a>

                  <label className="label" htmlFor="lname">
                     Last Name
                  </label>
                  <input
                     className="input"
                     type="text"
                     id="lname"
                     onChange={handleInput}
                     value={userData.lname}
                     onFocus={() => setLnameFocus(true)}
                  />
                  <a href className="error">
                     {lnameError}
                  </a>

                  <label className="label" htmlFor="phone_number">
                     Mobile Number
                  </label>
                  <input
                     className="input"
                     id="mobile_number"
                     type='tel'
                     maxLength="10"
                     onChange={handleInput}
                     value={userData.mobile_number}
                     onFocus={() => setMobileFocus(true)}
                  />
                  <a href className="error">
                     {mobileError}
                  </a>

                  <label className="label" htmlFor="email">
                     Email
                  </label>
                  <input
                     className="input"
                     type="email"
                     id="email"
                     onChange={handleInput}
                     value={userData.email}
                     onFocus={() => setEmailFocus(true)}
                  />
                  <a href className="error">
                     {emailError}
                  </a>

                  <label className="label" htmlFor="designation">
                     Designation
                  </label>
                  <Select
                     className="dropdown"
                     options={designationOptions}
                     id="designation"
                     placeholder=""
                     value={userDesignation}
                     onChange={setUserDesignation}
                  />
                  <a href className="error">
                     {DesignationError}
                  </a>

                  <label className="label" htmlFor="hospital">
                     Affiliated Institute/Hospital/Clinic
                  </label>
                  <input
                     className="input"
                     id="hospital"
                     placeholder=""
                     value={userData.hospital}
                     onChange={handleInput}
                     onFocus={() => setHospFocus(true)}
                  />
                  <a href className="error">
                     {HospError}
                  </a>

                  <label className="label" htmlFor="state">
                     State
                  </label>
                  <input
                     className="input"
                     id="state"
                     placeholder=""
                     value={userData.state}
                     onChange={handleInput}
                     onFocus={() => setStateFocus(true)}
                  />
                  <a href className="error">
                     {stateError}
                  </a>
                  <div className="suggestionsDiv">
                     {
                        suggestionsState.map((value, key) => {
                           return (
                              <p className="suggestion" key={key} onClick={() => itemClickState(value)}>{value}</p>
                           )
                        })
                     }
                  </div>

                  <label className="label" htmlFor="city">
                     City
                  </label>
                  <input
                     className="input"
                     id="city"
                     placeholder=""
                     value={userData.city}
                     onChange={handleInput}
                     onFocus={() => { setCityFocus(true) }}
                  />
                  <a href className="error">
                     {cityError}
                  </a>
                  {
                     suggestions.length !== 0 && (
                        <div className="suggestionsDiv">
                           {
                              suggestions.map((value, key) => {
                                 return (
                                    <p className="suggestion" key={key} onClick={() => itemClick(value)}>{value}</p>

                                 )
                              })
                           }
                        </div>
                     )
                  }

                  <div className="button-div">
                     <button className="r-register-button" id="register" type="submit">
                        {!isLoading ? 'Register' : <ClipLoader color="#fff" size="20"></ClipLoader>}
                     </button>
                  </div>
               </form>
            </div>
         </div>
         <div className="medisageLogo">
            <img src={Madisage} alt="logo" width="100px" />
         </div>
      </div>
   );
}

export default Register;

